// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-options-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup-option {
    cursor: pointer;
    padding: 15px 30px;
    margin: 0;
    width: 100%;
    max-width: 300px;
    background-color: #007bff;
    color: white;
    border-left: 0.2px solid grey;
    border-right: 0.2px solid grey;
    border-top: 0.2px solid grey;
    transition: background-color 0.3s;
}

.signup-option:hover,
.signup-option.selected {
    background-color: #0056b3;
}

.signup-option:last-child {
    border-bottom: 0.2px solid grey;
}

.signin-message {
    margin-top: 20px;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/signup/styles.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,UAAU;IACV,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,yBAAyB;IACzB,YAAY;IACZ,6BAA6B;IAC7B,8BAA8B;IAC9B,4BAA4B;IAC5B,iCAAiC;AACrC;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".signup-options-list {\n    list-style-type: none;\n    padding: 0;\n    margin: 0;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.signup-option {\n    cursor: pointer;\n    padding: 15px 30px;\n    margin: 0;\n    width: 100%;\n    max-width: 300px;\n    background-color: #007bff;\n    color: white;\n    border-left: 0.2px solid grey;\n    border-right: 0.2px solid grey;\n    border-top: 0.2px solid grey;\n    transition: background-color 0.3s;\n}\n\n.signup-option:hover,\n.signup-option.selected {\n    background-color: #0056b3;\n}\n\n.signup-option:last-child {\n    border-bottom: 0.2px solid grey;\n}\n\n.signin-message {\n    margin-top: 20px;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
